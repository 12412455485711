<template>
  <div>
    <div :class="{'opacity-30': !getSaveLink.showLinkExpiry}" v-if="toggle">
      <p class="text !text-[#202324] font-bold">Link Expiry</p>
      <b-form-datepicker id="example-datepicker" dropleft offset="10, 0"
                         v-model="getSaveLink.link_expire_attributes.expiry"
                         :disabled="!getSaveLink.showLinkExpiry"
                         class="data-picker mt-6"></b-form-datepicker>
      <b-form-timepicker id="datepicker-valid" class="time-picker mt-[0.75rem]" dropleft offset="10, 0"
                         v-model="getSaveLink.link_expire_attributes.expiry_time" :disabled="!getSaveLink.showLinkExpiry"></b-form-timepicker>
      <URLInputFloatingV2
        class="mt-[0.75rem]"
        id="link_expiry_logo"
        v-model="getSaveLink.link_expire_attributes.link_expiry_logo"
        :validations="validations"
        :disabled="!getSaveLink.showLinkExpiry"
        type="text"
        label="Link expiry logo"
        customClass="h-[3.5rem] bg-[#F4F6FA]"
        :errorType="validations.url || validations.urlLength || validations.urlValid  ? 'danger' : ''"
      >
        <template v-slot:error_message>
          <InputFieldMessage v-if="validations.url" :message="URLMessages.url"></InputFieldMessage>
          <InputFieldMessage v-else-if="validations.urlLength" :message="URLMessages.urlLength"></InputFieldMessage>
          <InputFieldMessage v-else-if="validations.urlValid" :message="URLMessages.urlValid"></InputFieldMessage>
        </template>
      </URLInputFloatingV2>
      <ImageUploadV2
        customClass="mt-[0.75rem] h-32"
        @click="getSaveLink.link_expire_attributes.link_expiry_logo = $event"
        :disabled="!getSaveLink.showLinkExpiry"
        :image="getSaveLink.link_expire_attributes.link_expiry_logo"
      ></ImageUploadV2>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { URLMessages, URLValidations } from '@/common/attributes'

export default {
  props: ['toggle'],
  data() {
    return {
      URLValidations: URLValidations,
      URLMessages: URLMessages,
      validations: {},
      messages: {}
    }
  },
  created () {
    this.validations = {
      ...URLValidations,
      expiry: false,
      expiryTime: false
    }
    this.messages = {
      ...URLMessages,
      expiry: 'Expiry date is required.',
      expiryTime: 'Time is required.'
    }
  },
  computed: {
    ...mapGetters(['getSaveLink'])
  },
  components: {
    ImageUploadV2: () => import('@/ui/ui-kit/v2/ImageUploadV2.vue'),
    URLInputFloatingV2: () => import('@/ui/ui-kit/v2/URLInputFloatingV2.vue'),
    InputFieldMessage: () => import('@/ui/ui-kit/v2/InputFieldMessage.vue'),
  },
  methods: {
    validateLinkExpiry() {
      let validate = false
      if (this.getSaveLink.showLinkExpiry) {
        this.validations.expiry = this.requiredCheck(this.getSaveLink.link_expire_attributes.expiry)
        this.validations.expiryTime = this.requiredCheck(this.getSaveLink.link_expire_attributes.expiry_time)
        this.validations.url = this.requiredCheck(this.getSaveLink.link_expire_attributes.link_expiry_logo)
        this.validations.urlLength = !this.maxLength(this.getSaveLink.link_expire_attributes.link_expiry_logo, 500)
        console.log(this.validations)
        if(this.getSaveLink.link_expire_attributes.link_expiry_logo && this.getSaveLink.link_expire_attributes.link_expiry_logo.length) {
          this.getSaveLink.link_expire_attributes.link_expiry_logo = this.urlConcatenation(this.getSaveLink.link_expire_attributes.link_expiry_logo)
          this.validations.urlValid = !this.isValidURL(this.getSaveLink.link_expire_attributes.link_expiry_logo)
        }
        validate = Object.keys(this.validations).every(k => this.validations[k] == false)
      }
      return validate
    }
  },
  watch: {
    'getSaveLink.link_expire_attributes.link_expiry_logo' (value) {
      if(value && value.length > 8) {
        this.validations.url = this.requiredCheck(value)
        this.validations.urlLength = !this.maxLength(value, 500)
        this.getSaveLink.link_expire_attributes.link_expiry_logo = this.urlConcatenation(this.getSaveLink.link_expire_attributes.link_expiry_logo)
        this.validations.urlValid = !this.isValidURL(this.getSaveLink.link_expire_attributes.link_expiry_logo)
      }
    },
  }
}
</script>
<style lang="less">
.b-form-datepicker, .b-form-timepicker {
  padding: 0 0.6rem !important;
  border-radius: 0.5rem !important;
  border: 1px solid #F4F6FA !important;
  background: #F4F6FA !important;
  height: 3.5rem !important;
  flex-direction: row-reverse;
  align-items: center !important;
}
</style>
